import React from 'react';
import { Flex, Image } from 'theme-ui';

function ErrorPage({ imageSrc, altText }) {
	return (
		<Flex
			as="main"
			sx={{
				height: '100vh',
				width: '100vw',
				justifyContent: 'center',
				alignItems: 'center',
				flexDirection: 'column',
			}}>
			<Image src={imageSrc} alt={altText} sx={{ width: ['100%', null, '75%', '50%'] }} />
			<div>
				<h2 id="errorText">O-o-oh! Something broke.</h2>
				<a id="errorLink" href="/">
					Go Back
				</a>
			</div>
		</Flex>
	);
}

ErrorPage.defaultProps = {
	imageSrc: '/images/svg/Error.svg',
	altText: '404 Error',
};

export default ErrorPage;
